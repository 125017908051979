var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-filters-section", { attrs: { "top-img": "/img/map-bg.png" } }, [
        _c("div", { staticClass: "text-on-map" }, [
          _c("h1", { staticClass: "ml-5" }, [_vm._v("Land Lottery")]),
          _c("ul", {}, [
            _vm._v(
              " Each stake of " +
                _vm._s(_vm._f("localFormatNumber")(100000)) +
                " SCRL gives you one lottery ticket. You can get as many tickets as you want by making multiply stakes"
            ),
            _c("br"),
            _vm._v(
              " You can start staking any time between 1 February and 28 February, 23:59 UTC"
            ),
            _c("br"),
            _c("br"),
            _vm._v(" The winners will be announced on 1 March 2022, 5pm UTC."),
            _c("br"),
          ]),
        ]),
      ]),
      !_vm.isWalletConnected
        ? _c("div", { staticClass: "page-content" }, [_c("connect-wallet")], 1)
        : _vm._e(),
      _vm.isWalletConnected
        ? _c(
            "div",
            { staticClass: "page-content" },
            [
              _vm.loadingStaking
                ? _c("v-progress-circular", {
                    staticStyle: { width: "100% !important" },
                    attrs: { size: "300", indeterminate: "" },
                  })
                : _vm._e(),
              !_vm.loadingStaking
                ? _c(
                    "v-row",
                    { staticClass: "stake-chests" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "buttons-all",
                          attrs: { cols: "12", lg: "5", xl: "3" },
                        },
                        [
                          _vm.isTimeUp
                            ? _c("div", [
                                !_vm.isTimeUpLotteryRolling
                                  ? _c(
                                      "div",
                                      [
                                        _vm._v(
                                          " The winners will be announced"
                                        ),
                                        _c("br"),
                                        _vm._v("on 1 March 2022, 5pm UTC. "),
                                        _c("br"),
                                        _c(
                                          "v-chip",
                                          {
                                            staticStyle: {
                                              "font-size": "18px",
                                            },
                                            attrs: { color: "primary" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              [
                                                _vm._v(" In: "),
                                                _c("timer", {
                                                  attrs: {
                                                    "deadline-string":
                                                      _vm.lotteryRolling,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isTimeUpLotteryRolling
                                  ? _c(
                                      "div",
                                      [
                                        _c("land-lottery-whitelist", {
                                          attrs: {
                                            stakesAmount: _vm.myStakes.length,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          !_vm.isTimeUp
                            ? _c(
                                "div",
                                [
                                  _c("v-row", { staticClass: "text-center" }, [
                                    _c("h3", { staticClass: "ma-auto" }, [
                                      _vm._v("How To stake"),
                                    ]),
                                  ]),
                                  _c(
                                    "v-row",
                                    { staticClass: "text-center mt-4" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "4",
                                            lg: "12",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/img/lottery/icon_circle_approve.svg",
                                            },
                                          }),
                                          _c("br"),
                                          _vm._v(
                                            ' Click "1. Approve" and confirm the transaction to allow the contract to use your SCRL '
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "4",
                                            lg: "12",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/img/lottery/icon_circle_chest.svg",
                                            },
                                          }),
                                          _c("br"),
                                          _vm._v(
                                            ' Choose the number of how many stakes you want to make, click "2. Stake" and confirm the transaction '
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "4",
                                            lg: "12",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/img/lottery/icon_circle_clock.svg",
                                            },
                                          }),
                                          _c("br"),
                                          _vm._v(
                                            " Wait for the lottery results on 1 March :) "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: "/img/lottery/icon_circle_chest.svg",
                                                },
                                              }),
                                              _c(
                                                "v-text-field",
                                                {
                                                  staticClass:
                                                    "toStakeAmountInput pull-right",
                                                  attrs: {
                                                    disabled: _vm.loadingData,
                                                    type: "number",
                                                    min: "0",
                                                  },
                                                  model: {
                                                    value: _vm.toStakeAmount,
                                                    callback: function ($$v) {
                                                      _vm.toStakeAmount = $$v
                                                    },
                                                    expression: "toStakeAmount",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        slot: "prepend",
                                                        disabled:
                                                          _vm.loadingData,
                                                      },
                                                      on: {
                                                        click: _vm.decrement,
                                                      },
                                                      slot: "prepend",
                                                    },
                                                    [_vm._v(" mdi-minus ")]
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        slot: "append",
                                                        disabled:
                                                          _vm.loadingData,
                                                      },
                                                      on: {
                                                        click: _vm.increment,
                                                      },
                                                      slot: "append",
                                                    },
                                                    [_vm._v(" mdi-plus ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "clear",
                                              }),
                                              _c("img", {
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                },
                                                attrs: {
                                                  src: "/img/scroll_48.png",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "pull-right" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      class:
                                                        _vm.chosenMoreTokenThanAvailable(
                                                          _vm.toStakeAmount
                                                        )
                                                          ? "color-red"
                                                          : "",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "localFormatNumber"
                                                            )(
                                                              _vm._f(
                                                                "removeDecimals"
                                                              )(
                                                                _vm.stakeTokenAmount(
                                                                  _vm.toStakeAmount
                                                                )
                                                              )
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " / " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "localFormatNumber"
                                                        )(
                                                          _vm._f(
                                                            "removeDecimals"
                                                          )(_vm.web3Balance)
                                                        )
                                                      ) +
                                                      " SCRL "
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "clear",
                                              }),
                                              _c("br"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "pull-left col-6",
                                                  attrs: {
                                                    large: "",
                                                    disabled: _vm.approved,
                                                    loading: _vm.loadingData,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.approve()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 1. Approve ")]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    disabled: !(
                                                      !_vm.approved ||
                                                      _vm.chosenMoreTokenThanAvailable(
                                                        _vm.toStakeAmount
                                                      )
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "pull-right col-6 p-0 m-0",
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "d-block full-size",
                                                                    attrs: {
                                                                      large: "",
                                                                      disabled:
                                                                        !_vm.approved ||
                                                                        _vm.chosenMoreTokenThanAvailable(
                                                                          _vm.toStakeAmount
                                                                        ),
                                                                      loading:
                                                                        _vm.loadingData,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.newStake(
                                                                            _vm.toStakeAmount
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " 2. Stake "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2024270264
                                                  ),
                                                },
                                                [
                                                  !_vm.approved
                                                    ? _c("span", [
                                                        _vm._v(
                                                          ' You have to "approve" spending tokens by the contract first '
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.approved &&
                                                  _vm.chosenMoreTokenThanAvailable(
                                                    _vm.toStakeAmount
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "You try to stake more tokens than you have"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-2",
                          staticStyle: { position: "relative" },
                          attrs: { cols: "12", lg: "7", xl: "9" },
                        },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "hidden-md-and-down",
                              attrs: { id: "timer-end", color: "primary" },
                            },
                            [
                              !_vm.isTimeUp
                                ? _c(
                                    "span",
                                    [
                                      _vm._v("To end: "),
                                      _c("timer", {
                                        attrs: {
                                          "deadline-string":
                                            _vm.stakingDeadline,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isTimeUp
                                ? _c("span", [
                                    _vm._v("Ended on"),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(_vm.stakingDeadline)),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c("h1", [_vm._v("Lottery stakes")]),
                          _c("span", [
                            _vm._v(
                              "Click on active chest to unstake and take back your SCRL."
                            ),
                          ]),
                          _c("br"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.loadingData ||
                                  !_vm.isTimeUp ||
                                  0 === _vm.activeStakes.length,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.unStakeAll()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { height: "25px" },
                                attrs: { src: "/img/staking/icon-chest.svg" },
                              }),
                              _vm._v(" Unstake all   "),
                              _c("span", { staticClass: "text-lowercase" }, [
                                _vm._v(
                                  " (" +
                                    _vm._s(
                                      Math.min(
                                        _vm.activeStakes.length,
                                        _vm.maxClaimAmount
                                      )
                                    ) +
                                    "x) "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              0 === _vm.myStakes.length
                                ? _c("v-col", [
                                    _c("h3", { staticClass: "text-center" }, [
                                      _vm._v("- No stakes -"),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._l(_vm.myStakes, function (stakeObj, index) {
                                return _c(
                                  "v-col",
                                  {
                                    key: stakeObj.id,
                                    staticClass: "single-stake",
                                    attrs: {
                                      cols: "6",
                                      sm: "2",
                                      "data-stake-id": stakeObj.id,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-chip",
                                      { staticClass: "ticket-number" },
                                      [_vm._v(_vm._s(stakeObj.id))]
                                    ),
                                    _vm.isStatusUnactive(stakeObj)
                                      ? _c("stake-unactive-chest", {
                                          staticClass: "chest-unactive",
                                          attrs: {
                                            "stake-obj": stakeObj,
                                            "loading-data": _vm.loadingData,
                                            "stake-period-second":
                                              _vm.stakePeriodSecond,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isStatusActive(stakeObj)
                                      ? _c("stake-active-chest", {
                                          staticClass: "chest-active",
                                          attrs: {
                                            "stake-obj": stakeObj,
                                            "loading-data": _vm.loadingData,
                                            "chest-loading":
                                              _vm.activeChestLoading,
                                          },
                                          on: {
                                            "unstake-event": _vm.unstakeHandler,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isStatusOpened(stakeObj)
                                      ? _c("stake-claimed-chest", {
                                          staticClass: "chest-opened",
                                          attrs: {
                                            "stake-obj": stakeObj,
                                            "loading-data": _vm.loadingData,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }