var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex-column container",
      class: _vm.displayLoading ? "chest-loading" : "",
      staticStyle: { position: "relative" },
      on: {
        click: function ($event) {
          return _vm.claimClick(_vm.stakeObj.id)
        },
      },
    },
    [
      _c("img", { attrs: { src: "/img/staking/chest-winner.png" } }),
      _c("div", { staticClass: "mb-2 flex flex-column" }, [
        _vm._v(" Final amount:"),
        _c("br"),
        _c("span", { staticClass: "text-amount" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("localFormatNumber")(
                  _vm._f("removeDecimals")(_vm.stakeObj.finalAmount)
                )
              ) +
              " SCRL "
          ),
        ]),
      ]),
      _c("p", [_vm._v(" Click to claim Land ")]),
      _vm.displayLoading
        ? _c("v-progress-circular", {
            staticStyle: {
              position: "absolute !important",
              top: "40%",
              left: "40%",
              "z-index": "100",
            },
            attrs: { indeterminate: "", size: 100, color: "amber" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }