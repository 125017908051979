<template>
    <v-container>
        <page-filters-section top-img="/img/map-bg.png">
            <div class="text-on-map">
                <h1 class="ml-5">Land Lottery</h1>
                <ul class="">
                    Each stake of {{ 100000 | localFormatNumber }} SCRL gives you one lottery ticket. You can get as
                    many tickets as you want by
                    making multiply stakes<br/>
                    You can start staking any time between 1 February and 28 February, 23:59 UTC<br/><br/>
                    The winners will be announced on 1 March 2022, 5pm UTC.<br/>
                </ul>
            </div>
        </page-filters-section>

        <div v-if="!isWalletConnected" class="page-content">
            <connect-wallet></connect-wallet>
        </div>
        <div v-if="isWalletConnected" class="page-content">
            <v-progress-circular
                v-if="loadingStaking"
                size="300"
                indeterminate
                style="width: 100% !important;"
            ></v-progress-circular>
            <v-row class="stake-chests" v-if="!loadingStaking">
                <v-col cols="12" lg="5" xl="3" class="buttons-all">
                    <div v-if="isTimeUp">
                        <div v-if="!isTimeUpLotteryRolling">
                            The winners will be announced<br/>on 1 March 2022, 5pm UTC.
                            <br/>
                            <v-chip color="primary" style="font-size: 18px;">
                            <span>
                                In: <timer :deadline-string="lotteryRolling"></timer>
                            </span>
                            </v-chip>
                        </div>
                        <div v-if="isTimeUpLotteryRolling">
                            <land-lottery-whitelist :stakesAmount="myStakes.length"></land-lottery-whitelist>
                        </div>
                    </div>
                    <div v-if="!isTimeUp">
                        <v-row class="text-center">
                            <h3 class="ma-auto">How To stake</h3>
                        </v-row>
                        <v-row class="text-center mt-4">
                            <v-col cols="4" lg="12" xl="4">
                                <img src="/img/lottery/icon_circle_approve.svg"/><br/>
                                Click "1. Approve" and confirm the transaction to allow the contract to use your SCRL
                            </v-col>
                            <v-col cols="4" lg="12" xl="4">
                                <img src="/img/lottery/icon_circle_chest.svg"/><br/>
                                Choose the number of how many stakes you want to make, click "2. Stake" and confirm the
                                transaction
                            </v-col>
                            <v-col cols="4" lg="12" xl="4">
                                <img src="/img/lottery/icon_circle_clock.svg"/><br/>
                                Wait for the lottery results on 1 March :)
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-card>
                                    <img src="/img/lottery/icon_circle_chest.svg"/>
                                    <v-text-field
                                        v-model="toStakeAmount"
                                        class="toStakeAmountInput pull-right"
                                        v-bind:disabled="loadingData"
                                        type="number"
                                        min="0"
                                    >
                                        <v-icon
                                            slot="prepend"
                                            @click="decrement"
                                            v-bind:disabled="loadingData"
                                        >
                                            mdi-minus
                                        </v-icon>
                                        <v-icon
                                            slot="append"
                                            @click="increment"
                                            v-bind:disabled="loadingData"
                                        >
                                            mdi-plus
                                        </v-icon>
                                    </v-text-field>
                                    <div class="clear"></div>
                                    <img src="/img/scroll_48.png" style="width: 20px;height:20px"/>
                                    <span class="pull-right">
                                    <span :class="chosenMoreTokenThanAvailable(toStakeAmount) ? 'color-red' : ''">
                                        {{ stakeTokenAmount(toStakeAmount) | removeDecimals | localFormatNumber }}
                                    </span>
                                    / {{ web3Balance | removeDecimals | localFormatNumber }} SCRL
                                </span>
                                    <div class="clear"></div>
                                    <br/>

                                    <v-btn
                                        large
                                        class="pull-left col-6"
                                        @click="approve()"
                                        v-bind:disabled="approved"
                                        v-bind:loading="loadingData"
                                    >
                                        1. Approve
                                    </v-btn>
                                    <v-tooltip top
                                               :disabled="!(!approved || chosenMoreTokenThanAvailable(toStakeAmount))"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-on="on" class="pull-right col-6 p-0 m-0">
                                                <v-btn
                                                    large
                                                    class="d-block full-size"
                                                    @click="newStake(toStakeAmount)"
                                                    v-bind:disabled="!approved || chosenMoreTokenThanAvailable(toStakeAmount)"
                                                    v-bind:loading="loadingData"
                                                >
                                                    2. Stake
                                                </v-btn>
                                            </div>
                                        </template>
                                        <span v-if="!approved">
                                            You have to "approve" spending tokens by the contract first
                                        </span>
                                        <span v-if="approved && chosenMoreTokenThanAvailable(toStakeAmount)">You try to stake more tokens than you have</span>
                                    </v-tooltip>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" lg="7" xl="9" class="mt-2" style="position:relative">
                    <v-chip id="timer-end" color="primary" class="hidden-md-and-down">
                        <span v-if="!isTimeUp">To end: <timer :deadline-string="stakingDeadline"></timer></span>
                        <span v-if="isTimeUp">Ended on<br/> {{ stakingDeadline }}</span>
                    </v-chip>
                    <h1>Lottery stakes</h1>
                    <span>Click on active chest to unstake and take back your SCRL.</span>
                    <br/>
                    <v-btn
                        v-bind:disabled="loadingData || !isTimeUp || 0===activeStakes.length"
                        @click="unStakeAll()"
                    >
                        <img src="/img/staking/icon-chest.svg" style="height: 25px;"/>
                        Unstake all &nbsp;
                        <span class="text-lowercase">
                            ({{ Math.min(activeStakes.length, maxClaimAmount) }}x)
                        </span>
                    </v-btn>
                    <v-row no-gutters>
                        <v-col v-if="0 === myStakes.length">
                            <h3 class="text-center">- No stakes -</h3>
                        </v-col>
                        <v-col cols="6" sm="2" v-for="(stakeObj,index) in myStakes" :key="stakeObj.id"
                               :data-stake-id="stakeObj.id"
                               class="single-stake">

                            <v-chip class="ticket-number">{{ stakeObj.id }}</v-chip>

                            <stake-unactive-chest
                                class="chest-unactive"
                                v-if="isStatusUnactive(stakeObj)"
                                v-bind:stake-obj="stakeObj"
                                v-bind:loading-data="loadingData"
                                v-bind:stake-period-second="stakePeriodSecond"
                            ></stake-unactive-chest>

                            <stake-active-chest
                                class="chest-active"
                                v-if="isStatusActive(stakeObj)"
                                v-bind:stake-obj="stakeObj"
                                v-bind:loading-data="loadingData"
                                v-bind:chest-loading="activeChestLoading"
                                @unstake-event="unstakeHandler"
                            ></stake-active-chest>

                            <!--                            <stake-winner-land-chest-->
                            <!--                                class="chest-to-claim-wizard"-->
                            <!--                                v-if="isStatusWinner(stakeObj)"-->
                            <!--                                v-bind:stake-obj="stakeObj"-->
                            <!--                                v-bind:loading-data="loadingData"-->
                            <!--                                v-bind:chest-loading="claimWizardChestLoading"-->
                            <!--                                @claim-winner-event="claimWizardHandler"-->
                            <!--                            ></stake-winner-land-chest>-->

                            <stake-claimed-chest
                                class="chest-opened"
                                v-if="isStatusOpened(stakeObj)"
                                v-bind:stake-obj="stakeObj"
                                v-bind:loading-data="loadingData"
                            ></stake-claimed-chest>
                            <!--                                                        Id: {{ stakeObj.id }}<br/>-->
                            <!--                                                        Index: {{ index }}<br/>-->
                            <!--                                                        Unstaked: {{ stakeObj.unstaked }}<br/>-->
                            <!--                                                        wizardToClaim: {{ stakeObj.isWinner }}<br/>-->
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import StakeUnactiveChest from "../components/stake/stake-unactive-chest";
import StakeActiveChest from "../components/stake/stake-active-chest";
import StakeClaimWizardChest from "../components/stake/stake-claim-wizard-chest";
import StakeClaimedChest from "../components/stake/stake-claimed-chest";
import DialogBox from "../components/dialog-box";
import Timer from "../components/timer";
import PageFiltersSection from "../components/page-filters-section";
import StakeWinnerLandChest from "../components/stake/stake-winner-land-chest";
import LandLotteryWhitelist from "../components/land-lottery-whitelist";
import {LandLotteryContract, lotteryPeriodSecond} from "../services/contracts/landLotteryContract";
import ConnectWallet from "@/views/ConnectWallet";

export default {
    components: {
        ConnectWallet,
        LandLotteryWhitelist,
        StakeWinnerLandChest,
        PageFiltersSection,
        Timer,
        DialogBox, StakeClaimedChest, StakeClaimWizardChest, StakeActiveChest, StakeUnactiveChest
    },
    data() {
        return {
            landLotteryContract: null,
            lotteryRolling: '03/01/2022 17:00:00 UTC',
            stakingDeadline: '02/28/2022 23:59:59 UTC',
            toStakeAmount: 1,
            approved: false,
            singleStakeTokenAmount: 100000 * (10 ** 18),
            myStakes: [],
            maxClaimAmount: 25,
            stakePeriodSecond: lotteryPeriodSecond,
            claimedWinnerDialog: false,
            loadingData: true,
            loadingStaking: true,
            activeChestLoading: {},
            claimWizardChestLoading: {},
        }
    },
    mounted: function () {
        if (this.isWalletConnected) {
            this.fetchMyStakes();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.loadingStaking = false;
                this.fetchMyStakes();
            });
        }
    },
    computed: {
        web3Balance() {
            return this.web3.balance;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        isTimeUp() {
            return true; //time passed
            // return false; //TODO: test only!
            // return Math.floor((Date.parse(this.stakingDeadline) - Date.now()) / 1000) <= 0;
        },
        isTimeUpLotteryRolling() {
            return true; //time passed
            // return false; //TODO: test only!
            // return Math.floor((Date.parse(this.lotteryRolling) - Date.now()) / 1000) <= 0;
        },
        activeStakes() {
            return this.myStakes.filter(stake => this.isStatusActive(stake));
        },
    },
    methods: {
        stakeTokenAmount(stakes) {
            return stakes * this.singleStakeTokenAmount;
        },
        decrement() {
            this.toStakeAmount > 1 ? this.toStakeAmount-- : null;
        },
        increment() {
            if (!this.chosenMoreTokenThanAvailable(this.toStakeAmount + 1)) {
                this.toStakeAmount++
            }
        },
        chosenMoreTokenThanAvailable(tokensAmount) {
            return this.stakeTokenAmount(tokensAmount) > this.web3Balance;
        },
        approve() {
            this.loadingData = true;
            this.landLotteryContract.approveForLotteryStake()
                .then((res) => {
                    this.approved = true;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },

        calculateEndStakingDurationMs(startTimestamp) {
            startTimestamp = parseInt(startTimestamp);
            return ((startTimestamp + this.stakePeriodSecond) - Math.floor(Date.now() / 1000)) * 1000;
        },

        newStake(amount) {
            this.loadingData = true;
            this.landLotteryContract.landLotteryNewStakeMultiple(amount).then((res) => {
                this.fetchMyStakes();
            }).finally(() => {
                this.loadingData = false;
            });
        },

        async fetchMyStakes() {
            this.landLotteryContract = new LandLotteryContract(this.web3);

            this.loadingData = true;
            this.landLotteryContract.tokenAllowanceForLottery().then((allowance) => {
                if (allowance >= this.singleStakeTokenAmount) {
                    this.approved = true;
                }
            });

            this.landLotteryContract.landLotteryMyStakes().then((res) => {
                let tmpStakes = [];
                for (let i = 0; i < res._stakes.length; i++) {
                    let curStake = res._stakes[i];
                    tmpStakes[i] = {
                        id: parseInt(res._indexes[i]),
                        finalAmount: this.singleStakeTokenAmount,
                        timestamp: curStake.createdAt,
                        isWinner: curStake.isWinner,
                        unstaked: curStake.unstaked
                    }
                }
                this.myStakes = tmpStakes.sort((a, b) => (b.id - a.id));
            }).finally(() => {
                this.loadingData = false;
                this.loadingStaking = false;
            });
        },

        isStatusUnactive(stake) {
            return (!stake.unstaked && !stake.isWinner && this.calculateEndStakingDurationMs(stake.timestamp) >= 0);
        },
        isStatusActive(stake) {
            return (!stake.unstaked && !stake.isWinner && this.calculateEndStakingDurationMs(stake.timestamp) < 0);
        },
        // isStatusWinner(stake) { //Not used anymore
        //     return (!stake.unstaked && stake.isWinner);
        // },
        isStatusOpened(stake) {
            return (stake.unstaked);
        },
        unstakeHandler(id) {
            this.unStake(id);
        },
        unStake(id) {
            this.loadingData = true;
            this.setActiveChestLoadingValue(id, true);
            this.landLotteryContract.landLotteryUnstake(id)
                .then((res) => {
                    // this.fetchMyStakes();
                    window.location.reload();
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingData = false;
                    this.setActiveChestLoadingValue(id, false);
                });
        },
        unStakeAll() {
            this.loadingData = true;
            // this.setActiveChestLoadingValue(id, true);
            let stakeIds = this.activeStakes.map(stake => stake.id);
            this.landLotteryContract.landLotteryUnstakeMultiple(stakeIds).then((res) => {
                this.fetchMyStakes();
            }).finally(() => {
                this.loadingData = false;
                // this.setActiveChestLoadingValue(id, false);
            });
        },
        setActiveChestLoadingValue(id, val) {
            let tmp = JSON.parse(JSON.stringify(this.activeChestLoading));
            tmp[id] = val;
            this.activeChestLoading = tmp;
        },
    }
}
</script>

<style lang="scss" scoped>
.toStakeAmountInput {
    width: 80%;
}

.toStakeAmountInput::v-deep input {
    text-align: center;
}

.color-red {
    color: red;
}

#timer-end {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 120%;
    padding: 20px;
}

.single-stake {
    margin: 10px 0;
    padding: 0;
    text-align: left;
    position: relative;

    .ticket-number {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
    }

    > div {
        padding: 0;
        width: 90%;
        height: 100%;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        border-radius: 5px;
        background-color: rgba(255, 255, 255, .15);
        backdrop-filter: blur(5px);
        cursor: pointer;
    }
}

.single-stake::v-deep img {
    width: 100%;
}

.buttons-all button {
    align-self: self-start;
}

.buttons-all img {
    height: 30px;
}

.single-stake .chest-unactive:hover::v-deep img,
.single-stake .chest-opened:hover::v-deep img {
    animation: shake 4s;
    animation-iteration-count: infinite;
    cursor: not-allowed;
}

.single-stake .chest-active::v-deep img,
.single-stake .chest-to-claim-wizard::v-deep img {
    animation: shake 6s;
    animation-iteration-count: infinite;
}

.single-stake .chest-active:hover::v-deep img,
.single-stake .chest-to-claim-wizard:hover::v-deep img {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

.text-on-map {
    color: #efefef;
    text-align: center;
    font-size: 110%;
    margin-top: -15px;
    z-index: 1;
    position: relative
}

@media only screen and (max-width: $md) {
    .text-on-map {
        font-size: 1rem;
    }
}

@media only screen and (max-width: $xs) {
    .text-on-map {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: $xxs) {
    .text-on-map {
        font-size: 0.7rem;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}
</style>
